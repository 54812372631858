<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-card-header>
            <i class="icon-menu mr-1"></i>List of Event Banner
            <div class="card-header-actions"></div>
          </b-card-header>
          <!-- <b-card-body>
            <ul class="px-0 my-0 banner-list" style="list-style-type: none">
              <li>
                <div class="row">
                  <div class="col-2">Event Banner Id</div>
                  <div class="col-2">Event Banner</div>
                  <div class="col-2">Event Banner Url</div>
                  <div class="col-2">Edit</div>
                </div>
              </li>
            </ul>

            <ul class="px-0 mt-0 banner-list" style="list-style-type: none">
              
                <li v-for="(item, index) in eventBannerList" :key="index">
                  <div class="row">
                    <div class="col-2">{{ item.eh_banners_id }}</div>
                    <div class="col-2">{{ item.eh_banner }}</div>
                    <div class="col-2">{{ item.eh_url }}</div>
                    
                    <div class="col-2">
                      <i
                        class="fa fa-edit"
                        @click="
                          editBanner(
                            item.eh_banners_id
                          )
                        "
                      ></i>
                    </div>
                  </div>
                </li>
            </ul>
        
          </b-card-body> -->

          <b-card-body>
            <v-client-table
              :columns="columns"
              :data="data"
              :options="options"
              :theme="theme"
              id="dataTable"
            >

              <div slot="eh_banners_id" slot-scope="props" class="update-center">
                <span>{{props.row.eh_banners_id}}</span>
              </div>
              <div slot="eh_banner" slot-scope="props" class="update-center">
                <span>
                  <b-img width="200px" thumbnail fluid :src="'https://adminapi-wanastime.wanasatime.com/uploads/'+ props.row.eh_banner" alt="Image 1"></b-img>
                </span>
              </div>

              <div slot="status" slot-scope="props" class="update-center">
                <span>{{props.row.status == 1 ? "Active" : "InActive"}}</span>
              </div>

              <div slot="ID" slot-scope="props" class="update-center">
                <i class="fa fa-edit" @click="editBanner(props.row.eh_banners_id)"></i>
              </div>

            </v-client-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import draggable from "vuedraggable";

Vue.directive("sortable", {
  inserted: function (el, binding) {
    var sortable = new Sortable(el, binding.value || {});
  },
});
export default {
  data() {
    return {
      columns: [
        "eh_banners_id",
        "eh_title",
        "eh_banner",
        "status",
        "ID"
      ],
      data: [],
      options: {
        headings: {
          eh_banners_id: "Event Banner Id",
          eh_title: "Event Name",
          eh_banner: "Banner Image",
          status: "Status",
          ID: "Update"       
        },
        sortable: ["eh_title"],
        filterable: ["eh_title"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      eventBannerList: [],
      searchData: "",
      // SetLabelIcon
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  components: {
    cSwitch,
    draggable,
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.getEventBannerList();
  },
  mounted: function () {},
  methods: {
    
    getEventBannerList: function() {
        MasterService.getEventBannerList()
        .then((response) => {
            const { data } = response;
          if (data.data.length > 0) {
            this.eventBannerList = response.data.data;
            this.data = response.data.data;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error)=> {
            console.log("Catch on Events Category Error ", error);
            this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    editBanner: function (id) {
        if(id){
            this.$router.push({
                path:'/events/addeventbanner',
                query:{bannerId: id}
            })
        }
    },
   
    // Show Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  updated() {},
};
</script>

<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
.banner-list li {
  padding: 20px;
  list-style-type: none;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #eee;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.banner-list li .row .col-2,
.col-3 {
  text-align: center;
}
.update-center {
  text-align: center;
}
</style>
